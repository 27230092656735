import http from "@/utils/request";

export function getList(params) {
    return http({
        url: '/o2oOrderDetail/list.nd?formCode=ordero2o',
        method: 'get',
        params
    })
}
export function getExport(params) {
    return http({
        url: '/o2oOrderDetail/export.nd',
        method: 'get',
        params,
        responseType: 'blob'
    })
}