import {
    getList,
    getExport
} from './api';
import Util from "@/utils/utils";
export default {
    name: 'inspection',
    data() {
        return {
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/center/orderoTo",
                    name: "orderoTo",
                    title: "O2O订单明细"
                }
            ],
            columns: [{
                    label: '子单ID',
                    prop: 'id',
                    width: 200,
                },
                {
                    label: '订单ID',
                    prop: 'orderId',
                    width: 200,
                },
                {
                    label: '店铺名称',
                    prop: 'shopName',
                    width: 250,
                },
                {
                    label: '店铺编码',
                    prop: 'shopCode',
                    width: 200,
                },
                {
                    label: '营销中心',
                    prop: 'financeOrgNew',
                    width: 200,
                },
                {
                    label: '型号',
                    prop: 'zzprdmodel',
                    width: 200,
                },
                {
                    label: '货源类型',
                    prop: 'sourceName',
                    width: 200,
                },
                {
                    label: '物料组',
                    prop: 'matkl',
                    width: 200,
                },
                {
                    label: '商品销售单价',
                    prop: 'billPrice',
                    width: 200,
                },
                {
                    label: '子单商品数量',
                    prop: 'qty',
                    width: 200,
                },
                {
                    label: '子订单总额',
                    prop: 'itemAmt',
                    width: 200,
                },
                {
                    label: '母订单总额',
                    prop: 'orderAmt',
                    width: 200,
                }, {
                    label: '商品优惠',
                    prop: 'itemDiscount',
                    width: 200,
                },
                {
                    label: '订单优惠',
                    prop: 'orderDiscount',
                    width: 200,
                },
                {
                    label: '平台优惠',
                    prop: 'platformDiscount',
                    width: 200,
                },
                {
                    label: '子订单应付金额',
                    prop: 'itemPayableAmt',
                    width: 200,
                },
                {
                    label: '母订单应付金额',
                    prop: 'orderPayableAmt',
                    width: 200,
                },
                {
                    label: '子订单实付金额',
                    prop: 'itemActualAmt',
                    width: 200,
                },
                {
                    label: '母订单实付金额',
                    prop: 'orderActualAmt',
                    width: 200,
                },
                {
                    label: '下单时间',
                    prop: 'orderDateStr',
                    width: 200,
                },
                {
                    label: '下单渠道',
                    prop: 'orderChannel',
                    width: 200,
                },
                {
                    label: '订单状态',
                    prop: 'status',
                    width: 200,
                },
                {
                    label: '支付时间',
                    prop: 'payDateStr',
                    width: 200,
                },
                {
                    label: '买家名称',
                    prop: 'buyerName',
                    width: 200,
                },
                {
                    label: '买家手机号',
                    prop: 'buyerMobile',
                    width: 150,
                },
                {
                    label: '收货人',
                    prop: 'contact',
                    width: 200,
                },
                {
                    label: '收货电话',
                    prop: 'contactPhone',
                    width: 150,
                },
                {
                    label: '省',
                    prop: 'province',
                    width: 100,
                },
                {
                    label: '市',
                    prop: 'city',
                    width: 100,
                },
                {
                    label: '县（区',
                    prop: 'region',
                    width: 100,
                },
                {
                    label: '乡镇街道',
                    prop: 'street',
                    width: 200,
                },
                {
                    label: '详细地址',
                    prop: 'address',
                    width: 250,
                    ellipsis: true,
                    align: "left"
                },
            ],
            tableData: [],
            data: [],
            pager: {
                pageNo: 1,
                pageSize: 10,
                count: 10
            },
            ssearchList: [],
            searchNumber: 0,
            isLoading: false,
            showLoading:false
        }
    },
    mounted() {
        this.tablelist();
    },

    methods: {
        exportData() {
            this.isLoading = true;
            let data = JSON.parse(JSON.stringify(this.ssearchList));
            data.pageSize = 300000;
            data.pageNo = 1;
            getExport(data).then(res => {
                this.isLoading = false;
                Util.blobToJson(res.data).then(content => {
                    if (content && content.msg == 'success') {
                        this.$message.success('下载成功，请到下载中心查看!')
                    }
                }).catch(err => {
                    Util.downloadFile(res.data, '我的o2o.xls')
                }).finally(() => {
                    this.expLoading = false;
                })
            })
        },
        inputSearch(data) {
            this.ssearchList = data[0];
            this.searchzz = true;
            this.tablelist();
        },
        pageSearch(pager) {
            this.pager.pageNo = pager.pageNo;
            this.pager.pageSize = pager.pageSize;
            this.tablelist();
        },
        tablelist() {
            let data = this.ssearchList;
            data.pageNo = this.pager.pageNo;
            data.pageSize = this.pager.pageSize;
            this.showLoading = true
            getList(data).then(res => {
                this.data = res.data.list;
                this.searchNumber = res.data.totalCount;
                this.pager.count = res.data.totalCount;
                this.showLoading = false
            })
        },

        // 分页
        pagesiezes(data, pageSize) {
            this.ssearchList.pageNo = data;
            this.ssearchList.pageSize = pageSize;
            this.tablelist();
        },
        handleChange(e) {
        },
    }
}